import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PageContext from '../contexts/page';
import { Button, Buttons, Grid, Cell } from 'ui';
import { Link } from 'gatsby';
import ArticleCards from './ArticleCards';
import { Container, TitleContainer } from 'ui/containers';
import { useMedia } from 'react-use';

const Text = styled.p`
  color: #6c6c6c;
  opacity: 0.8;
`;

const Travelogue = ({ articles }) => {
  const { t } = useTranslation();
  const { getLanguagePrefix } = useContext(PageContext);
  const isMobile = useMedia('(max-width: 750px)');

  return (
    <div>
      <Container>
        <Grid>
          <Cell sizes={{ default: 1, desktop: 1 }}>
            <TitleContainer
              carnet
              style={{ fontSize: isMobile ? '20px' : '32px' }}
            >
              {t('home.blog.title')}
            </TitleContainer>
          </Cell>
          <Cell sizes={{ default: 1, desktop: 1 / 2 }}>
            <Text>{t('home.blog.subtitle')}</Text>
          </Cell>
          <Cell sizes={{ default: 1 / 2, desktop: 1 / 2 }}>
            <Buttons>
              <Button
                variant="carnet"
                as={Link}
                to={getLanguagePrefix(t('url.articles'))}
                style={{ display: isMobile ? 'none' : '' }}
              >
                {t('home.blog.button')}
              </Button>
            </Buttons>
          </Cell>
        </Grid>

        <ArticleCards articles={articles} background={'#fff'} variant="home" />
      </Container>
      <Buttons>
        <Button
          allArticles
          variant="carnet"
          as={Link}
          to={getLanguagePrefix(t('url.articles'))}
          style={{ display: isMobile ? '' : 'none' }}
        >
          {t('home.blog.button')}
        </Button>
      </Buttons>
    </div>
  );
};

export default Travelogue;
