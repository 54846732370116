import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import { Button, media, SearchWrapper } from '../ui';
import backgroundHowItWorks from '../assets/images/BackgroundHowItWorks.png';
import SearchBar from 'components/SearchBar';
import { Container } from '../ui/containers';
import styled from 'styled-components';
import Travelogue from '../components/Travelogue';
import icPiggyBank from '../assets/images/ic-piggy-bank-outline.svg';
import icHeart from '../assets/images/ic-heart.svg';
import IcHelp from '../assets/images/icons/IcHelp';
import IcCharity from '../assets/images/icons/IcCharity';
import { graphql } from 'gatsby';
import highlight from '../assets/images/accent.svg';
import highlight2 from '../assets/images/accent-2.svg';
import AuthContext from '../contexts/auth';
import UtilsContext from 'contexts/util';
import { CONTACT_US } from 'components/UtilModal';
import HeroHeader from '../components/HeroHeader';

const TextBlock = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (Boolean(column) ? 'column' : 'row')};
  justify-content: center;
  align-items: center;

  p {
    max-width: 768px;
    text-align: left;
    ${({ theme }) => theme.fontStyles.bodyLarger};
    line-height: ${({ theme }) => theme.spacing(1.5)};
    letter-spacing: 0.6px;
  }
`;

const Point = styled.li`
  ${({ theme }) => theme.fontStyles.bodyLarger};
  line-height: ${({ theme }) => theme.spacing(1.75)};
  max-width: 768px;

  p {
    padding-right: ${({ theme }) => theme.spacing()};
    padding-bottom: ${({ theme }) => theme.spacing()};
    ${media.tablet`
      padding: ${({ theme }) => theme.spacing(1.5)};
    `}
  }
`;

const Highlight = styled.span`
  background-image: url(${({ second }) =>
    Boolean(second) ? highlight2 : highlight});
  background-repeat: no-repeat;
`;

const Colored = styled.span`
  color: ${({ theme }) => theme.secondary};
`;

const Bonus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: ${({ theme }) => theme.spacing(2)} 0;

  svg,
  img {
    height: ${({ theme }) => theme.spacing(2.5)};
    width: 10em;
    margin-right: ${({ theme }) => theme.spacing(0.75)};
    margin-bottom: ${({ theme }) => theme.spacing()};
  }

  div,
  span {
    max-width: 708px;
    ${({ theme }) => theme.fontStyles.h3};
  }

  ${media.tablet`
  flex-direction: row;
  align-items: flex-start;

    svg, img {
      width: ${({ theme }) => theme.spacing(2.5)};
      margin-bottom: 0;
    }
  `}

  @media screen and (min-width:750px) and (max-width: 850px) {
    svg {
      width: 4em;
    }
  }
`;

const Subtitle = styled.span`
  max-width: 768px;
  ${({ theme }) => theme.fontStyles.bodySmall};
  text-align: left;
  align-self: left;
`;

const TitleBlock = styled.h1`
  ${({ theme }) => theme.fontStyles.h1};
  text-align: center;
`;

export const query = graphql`
  query($language: String!) {
    allBlog(limit: 9, filter: { language: { eq: $language } }) {
      nodes {
        id
        slug
        displayName
        publishedAt
        excerpt
        picture {
          imagePath
          smallImagePath
        }
        thematic {
          displayName
        }
      }
    }
  }
`;

const HowItWorks = ({
  data: {
    allBlog: { nodes: articles },
  },
}) => {
  const { t } = useTranslation();

  const { isAuthenticated, handleAuth } = useContext(AuthContext);
  const { onUtilKindChange } = useContext(UtilsContext);

  return (
    <Layout>
      <SEO title={t('pageTitle.howItWorks')} />
      <HeroHeader
        title={t('header.howItWorks')}
        image={backgroundHowItWorks}
      />
      <Container>
        <TextBlock>
          <p>
            {t('howItWorks.intro.p1')}{' '}
            <Highlight second>{t('howItWorks.intro.highlight')}</Highlight>{' '}
            {t('howItWorks.intro.p2')}
          </p>
        </TextBlock>
        <SearchWrapper>
          <SearchBar variant="home" />
        </SearchWrapper>
        <TextBlock column>
          <TitleBlock>{t('howItWorks.further.title')}</TitleBlock>
          <p>
            {t('howItWorks.further.subtitle.p1')}{' '}
            <Highlight>{t('howItWorks.further.subtitle.highlight')}</Highlight>{' '}
            {t('howItWorks.further.subtitle.p2')}
          </p>
          <ol>
            <Point>
              {t('howItWorks.n1.title')}
              <p style={{ marginTop: '32px' }}>
                {t('howItWorks.n1.description.p1')}
                <Button
                  style={{ display: 'inline-block', margin: '0 8px' }}
                  variant="secondary"
                  onClick={!isAuthenticated && handleAuth}
                >
                  {t('modals.emailLogin.title')}
                </Button>

                {t('howItWorks.n1.description.p2')}
              </p>
            </Point>
            <Point>
              {t('howItWorks.n2.title')}
              <p>
                {t('howItWorks.n2.description.p1')}{' '}
                <Colored>{t('howItWorks.n2.description.colored')}</Colored>{' '}
                {t('howItWorks.n2.description.p2')}
              </p>
              <p style={{ paddingTop: 0 }}>
                {t('howItWorks.n2.subDescription')}
              </p>
            </Point>
            <Point>
              {t('howItWorks.n3.title')}
              <p style={{ paddingBottom: 0 }}>
                <Colored>{t('howItWorks.n3.description.colored')}</Colored>{' '}
                <br />
                {t('howItWorks.n3.description.p1')}
              </p>
              <p style={{ paddingTop: '-24px' }}>
                {t('howItWorks.n3.description2')}
              </p>
            </Point>
            <Point>
              {t('howItWorks.n4.title')}
              <p>
                {t('howItWorks.n4.description.p1')}{' '}
                <Colored>{t('howItWorks.n4.description.colored')}</Colored>{' '}
                {t('howItWorks.n4.description.p2')}
              </p>
            </Point>
            <Point>
              {t('howItWorks.n5.title')}
              <Bonus style={{ marginBottom: 0 }}>
                <img src={icPiggyBank} alt="Piggy bank" />
                <span>{t('howItWorks.n5.description.piggyBank.title')}</span>
              </Bonus>
              <Subtitle style={{ marginBottom: '32px' }}>
                {t('howItWorks.n5.description.piggyBank.subtitle')}
              </Subtitle>
              <Bonus>
                <IcCharity color="#AF84D7" />
                <span>{t('howItWorks.n5.description.charity')}</span>
              </Bonus>
              <Bonus>
                <img src={icHeart} alt="heart" />
                <span>{t('howItWorks.n5.description.heart')}</span>
              </Bonus>
              <Bonus>
                <IcHelp color="#73C992" />
                <span>{t('howItWorks.n5.description.help')}</span>
              </Bonus>
            </Point>
          </ol>
          <Button
            variant="secondary"
            style={{
              margin: '0 auto',
              height: '52px',
              width: '256px',
            }}
            onClick={() => onUtilKindChange(CONTACT_US)}
          >
            {t('aboutUs.contact')}
          </Button>
        </TextBlock>
      </Container>
      <Travelogue articles={articles} />
    </Layout>
  );
};

export default HowItWorks;
